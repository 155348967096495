import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import { Link, useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";

import pageOptions from "../../configs/pages.json";
import { toast } from "../../utils/storeDispatcher.js";

import { useLoading } from "../LoadingContext";

const ResponsiveAppBar = () => {
    const { showLoading, hideLoading } = useLoading();
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [, setAnchorElUser] = React.useState(null);

    const navigate = useNavigate();

    const userInfo = useStoreState((state) => state.userStore.user);
    const setUserInfo = useStoreActions((actions) => actions.userStore.setUser);

    const [pages, setPages] = React.useState([]);

    const updatePages = () => {
        setPages(
            pageOptions.filter((p) => {
                if (!p.is_admin_only) {
                    return p;
                } else if (userInfo?.user_type === "admin") {
                    return p;
                }
            })
        );
    };

    React.useEffect(() => {
        if (userInfo.id) {
            updatePages();
        }
    }, [userInfo]);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const logoutUser = () => {
        showLoading();
        axios
            .get("/auth/logout")
            .then(() => {
                setUserInfo({});
                updatePages();
                navigate("/");
                toast("You have been logged out.");
            })
            .then(() => {
                hideLoading();
            })
            .catch(() => {
                toast("Unable to logout, please try again!", "error");
                hideLoading();
            });
    };

    return (
        <AppBar
            position="static"
            sx={{
                backgroundColor: "var(--primary)",
            }}
        >
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{ mr: 2, display: { xs: "none", md: "flex" } }}
                        className="flex items-center space-x-4"
                    >
                        <a href="/">
                            <Avatar
                                alt="kurzgesagt logo"
                                src="/images/logo_anim.gif"
                            ></Avatar>
                        </a>
                        <h2 className="tracking-wider">BirdBot</h2>
                    </Typography>
                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: "top",
                                horizontal: "left",
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: "block", md: "none" },
                            }}
                        >
                            {pages.map((page) => (
                                <MenuItem
                                    key={page.name}
                                    onClick={handleCloseNavMenu}
                                >
                                    <Typography textAlign="center">
                                        <Link to={page.link}>{page.name}</Link>
                                    </Typography>
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                    <Typography
                        variant="h6"
                        noWrap
                        component="div"
                        sx={{
                            flexGrow: 1,
                            display: { xs: "flex", md: "none" },
                        }}
                        className="flex items-center space-x-4"
                    >
                        <a href="/">
                            <Avatar
                                alt="kurzgesagt logo"
                                src="/images//logo_anim.gif"
                            ></Avatar>
                        </a>
                        {/* <h2 className="tracking-widest ">BirdBot</h2> */}
                    </Typography>

                    <Box
                        sx={{
                            flexGrow: 1,
                            display: { xs: "none", md: "flex" },
                        }}
                    >
                        {pages.map((page) => (
                            <Button
                                key={page.name}
                                onClick={handleCloseNavMenu}
                                sx={{
                                    my: 2,
                                    color: "white",
                                    display: "block",
                                }}
                            >
                                <Link to={page.link}>{page.name}</Link>
                            </Button>
                        ))}
                    </Box>

                    <Box sx={{ flexGrow: 0 }} className="mx-2">
                        {userInfo && userInfo.id ? (
                            <Button variant="text" onClick={logoutUser}>
                                <p className="text-white">Logout</p>
                            </Button>
                        ) : (
                            <Button variant="text">
                                <a
                                    href={`${
                                        import.meta.env.PROD
                                            ? `${
                                                  import.meta.env
                                                      .VITE_BACKEND_API_PROD
                                              }/auth/login`
                                            : `${
                                                  import.meta.env
                                                      .VITE_BACKEND_API_DEV
                                              }/auth/login`
                                    }`}
                                    className="text-white"
                                >
                                    Login
                                </a>
                            </Button>
                        )}
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                            <Avatar
                                alt="User"
                                src={
                                    userInfo && userInfo.avatar
                                        ? userInfo.avatar
                                        : "/images/duck.png"
                                }
                            />
                        </IconButton>
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;
