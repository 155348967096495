import { GlobalStore } from "./globalStore";

export function toast(message, severity = "success") {
    try {
        GlobalStore.dispatch.toastContentStore.setToastContent({
            message,
            severity,
        });
    } catch (error) {
        // console.log(error);
    }
}

export function clearUserInfo() {
    try {
        GlobalStore.dispatch.userStore.setUser({});
    } catch (error) {
        // console.log(error);
    }
}
