import React, { lazy, Suspense, useEffect } from "react";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useStoreActions, useStoreState } from "easy-peasy";
import axios from "axios";

import NotFound from "./pages/NotFound";
import ResponsiveAppBar from "./components/appbars/AppBar";
import Footer from "./components/Footer";
import Toast from "./components/Toast";
import { useLoading } from "./components/LoadingContext";
import { Loader } from "./components/Loader";

// Lazy Loading
const Main = lazy(() => import("./pages/Main"));
const Admin = lazy(() => import("./pages/admin/Admin"));
const AdminModeration = lazy(() => import("./pages/admin/Moderation"));
const Banners = lazy(() => import("./pages/Banners"));

const App = () => {
    const userInfo = useStoreState((state) => state.userStore.user);
    const setUserInfo = useStoreActions((actions) => actions.userStore.setUser);
    const { showLoading, hideLoading } = useLoading();
    useEffect(() => {
        showLoading();

        axios
            .get("/users/@me")
            .then((res) => {
                setUserInfo(res.data.user_data);
            })
            .then(() => {
                hideLoading();
            })
            .catch((e) => {
                hideLoading();
            });
    }, []);

    return (
        <div className="flex flex-col h-screen">
            <Toast />
            <BrowserRouter>
                <Loader />
                <ResponsiveAppBar />
                <Suspense fallback={<Loader />}>
                    <Routes>
                        <Route path="/" element={<Main />} />
                        {userInfo?.id &&
                            userInfo?.user_type &&
                            userInfo?.user_type === "admin" && (
                                <Route path="/admin" element={<Admin />}>
                                    <Route
                                        path="/admin/moderation"
                                        element={<AdminModeration />}
                                    />
                                    <Route
                                        path="/admin/banners"
                                        element={<Banners />}
                                    />
                                </Route>
                            )}
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </Suspense>

                <div className="flex-1"></div>
                <Outlet />
                <Footer />
            </BrowserRouter>
        </div>
    );
};

export default App;
