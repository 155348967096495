import { FaPatreon } from "react-icons/fa";
import { ImEarth } from "react-icons/im";
import { SiTiktok } from "react-icons/si";
import {
    BsReddit,
    BsDiscord,
    BsTwitch,
    BsInstagram,
    BsFacebook,
    BsTwitter,
    BsYoutube,
    BsBehance,
} from "react-icons/bs";
import React from "react";

import socialsLinks from "../configs/socials.json";

const Footer = () => {
    const socials = [
        {
            name: "Website",
            icon: <ImEarth className="w-8 h-8" />,
        },
        {
            name: "Instagram",
            icon: <BsInstagram className="w-8 h-8" />,
        },
        {
            name: "Reddit",
            icon: <BsReddit className="w-8 h-8" />,
        },
        {
            name: "Discord",
            icon: <BsDiscord className="w-8 h-8" />,
        },
        {
            name: "Patreon",
            icon: <FaPatreon className="w-8 h-8" />,
        },
        {
            name: "Youtube",
            icon: <BsYoutube className="w-8 h-8" />,
        },
        {
            name: "Twitter",
            icon: <BsTwitter className="w-8 h-8" />,
        },
        {
            name: "Facebook",
            icon: <BsFacebook className="w-8 h-8" />,
        },
        {
            name: "Behance",
            icon: <BsBehance className="w-8 h-8" />,
        },
        {
            name: "Twitch",
            icon: <BsTwitch className="w-8 h-8" />,
        },
        {
            name: "TikTok",
            icon: <SiTiktok className="w-8 h-8" />,
        },
    ];

    return (
        <footer
            className="w-full flex flex-col items-center justify-center"
            style={{ backgroundColor: "var(--primary)" }}
        >
            <div className="m-2 p-4 grid gap-8 grid-cols-6 sm:grid-cols-12 text-white">
                {socials.map((social) => {
                    return (
                        <div key={social.name}>
                            <a
                                href={socialsLinks[social.name]}
                                target="_blank"
                                rel="noreferrer"
                            >
                                {social.icon}
                            </a>
                        </div>
                    );
                })}
            </div>
            <div className="m-2 text-xs text-gray-100 tracking-wide">
                Artwork Credit: Kurzgesagt - In A Nutshell&#8482;
            </div>
        </footer>
    );
};

export default Footer;
