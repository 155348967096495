import { createStore, action } from "easy-peasy";
const userStore = {
    user: {},
    setUser: action((state, payload) => {
        state.user = payload;
    }),
};

export const toastContentStore = {
    toastContent: {
        message: null,
        severity: "success",
        date: new Date(),
    },
    setToastContent: action((state, payload) => {
        state.toastContent = { ...payload, date: new Date() };
    }),
};

const globalStore = {
    userStore,
    toastContentStore,
};

export const GlobalStore = createStore(globalStore);
