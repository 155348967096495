import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import React from "react";

const NotFound = () => {
    let navigate = useNavigate();
    return (
        <div className="text-white text-4xl m-4 mt-8 p-2 flex flex-col space-y-4 justify-center items-center tracking-wide">
            <p className="text-6xl">404</p>
            <p>Page Not Found</p>
            <Button
                variant="contained"
                onClick={() => {
                    navigate("/");
                }}
            >
                Go to home
            </Button>
        </div>
    );
};

export default NotFound;
