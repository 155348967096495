import { Alert } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import React, { useState, useEffect } from "react";
import { useStoreState } from "easy-peasy";

const Toast = () => {
    const vertical = "top";
    const horizontal = "center";

    let toastContent = useStoreState(
        (state) => state.toastContentStore.toastContent
    );

    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (toastContent.message != null) {
            setOpen(true);
        }
    }, [toastContent.date]);

    return (
        <div>
            {toastContent && (
                <Snackbar
                    anchorOrigin={{ vertical, horizontal }}
                    open={open}
                    onClose={() => {
                        setOpen(false);
                    }}
                    key={vertical + horizontal}
                    autoHideDuration={5000}
                >
                    <Alert
                        onClose={() => {
                            setOpen(false);
                        }}
                        severity={toastContent.severity}
                    >
                        {toastContent.message}
                    </Alert>
                </Snackbar>
            )}
        </div>
    );
};

export default Toast;
