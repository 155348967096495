import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import { StoreProvider } from "easy-peasy";
import { GlobalStore } from "./utils/globalStore";
import { clearUserInfo, toast } from "./utils/storeDispatcher";
import { LoadingProvider } from "./components/LoadingContext";

axios.interceptors.request.use((config) => {
    config.withCredentials = true;
    return config;
});

axios.interceptors.response.use(
    (res) => {
        return res;
    },
    (err) => {
        if (err.response && err.response.status === 403) {
            toast("Unauthorized", "error");
            clearUserInfo();
            window.location.href = import.meta.env.WEBSITE_HOME_PAGE;
            return;
        }
        return Promise.reject(err);
    }
);

axios.defaults.baseURL = import.meta.env.PROD
    ? import.meta.env.VITE_BACKEND_API_PROD
    : import.meta.env.VITE_BACKEND_API_DEV;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <StoreProvider store={GlobalStore}>
            <LoadingProvider>
                <App />
            </LoadingProvider>
        </StoreProvider>
    </React.StrictMode>
);
