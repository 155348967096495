import React, { useRef, useEffect } from "react";
import { Box } from "@mui/material";
import { Typography } from "@mui/material";
import { useLoading } from "./LoadingContext";

const styles = {
    opacity: "0",
    transition: "all 0.5s",
};

export const Loader = () => {
    const ref = useRef();
    const { showLoading, hideLoading, isLoading } = useLoading();

    useEffect(() => {
        if (!isLoading) {
            handleFade(ref);
        }
    }, [ref, isLoading]);

    function handleFade(element) {
        const style = element.style;
        if (!style) return;

        style.transition = styles.transition;
        style.opacity = styles.opacity;
        hideLoading();
    }

    return isLoading ? (
        <>
            <div id="loader"></div>
            <Box>
                <Typography
                    variant="h3"
                    id="loader-text"
                    className="text-gray-600 text-xl font-poppins"
                >
                    Loading...
                </Typography>
            </Box>
        </>
    ) : null;
};
